<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h5">
        <router-link :to="{name: 'users'}">Users</router-link>
        <v-icon class="mx-2">mdi-chevron-right</v-icon>
        <span>{{username}}</span>
      </h2>
      <div>
        <v-btn v-if="IsForceChangePassword" text @click="resendLoginLink">
          <v-icon left>mdi-email</v-icon>
          Resend login link
        </v-btn>
        <v-btn v-if="canEdit" text :to="{name: 'user-edit'}">
          <v-icon left>mdi-pencil</v-icon>
          Edit
        </v-btn>
        
        <v-btn v-if="canDelete" text color="error" @click="deleteUser">
          <v-icon left>mdi-close</v-icon>
          Delete
        </v-btn>
      </div>
    </div>

    <!-- Alerts -->
    <status-alert :error="deleteError" />

    <!-- Details -->
    <v-card>
      <v-card-header>
        <v-card-title class="pa-0">Details</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <template v-slot:default>
          <v-row class="row-divided">
            <v-col cols="4">
              <label-value label="ID" :value="user.id" />
              <label-value label="Username" :value="user.username" />
            </v-col>
            <v-col cols="4">
              <label-value label="Status">
                <status-chip :status="user.status" small />
              </label-value>
              <label-value label="Enabled">
                <v-chip outlined small :color="user.status ? 'success' : 'error'">
                  <v-icon small left>{{user.status ? 'mdi-check' : 'mdi-close'}}</v-icon>
                  {{user.status ? 'Enabled' : 'Disabled'}}
                </v-chip>
              </label-value>
              <label-value label="Multi-factor authentication">
                <v-chip v-for="option in user.mfa_options" :key="option" small class="mr-1 text-uppercase">{{option}}</v-chip>
              </label-value>
            </v-col>
            <v-col cols="4">
              <label-value label="Created time" :value="$formatDateTime(user.create_time)" />
              <label-value label="Last updated time" :value="$formatDateTime(user.update_time)" />
            </v-col>
          </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Attributes -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">Attributes</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <template v-slot:default>
            <v-row class="row-divided">
              <v-col cols="4">
                <label-value label="Name" :value="user.name"></label-value>
                <label-value label="Avatar">
                  <user-avatar :user="user" />
                </label-value>
              </v-col>
              <v-col cols="8">
                <label-value label="Email address" :value="user.email"></label-value>
                <label-value label="Phone number" :value="user.phone_number"></label-value>
              </v-col>
            </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Permissions -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">Permissions</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <template #default>
            <codemirror v-model="userPermissions" class="editor" :options="codeOptions"/>
          </template>
        </async-state>
      </v-card-text>
    </v-card>
    <confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import LabelValue from "../components/LabelValue"
import StatusChip from "../components/StatusChip"
import UserAvatar from "../components/UserAvatar"
import StatusAlert from "../components/StatusAlert.vue"
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript.js'
import ConfirmDialog from "../components/ConfirmDialog"

export default {
  name: "DataDetail",
  components: {AsyncState, LabelValue, StatusChip, UserAvatar, codemirror, ConfirmDialog, StatusAlert},

  props: {
    username: String
  },

  data() {
    return {
      user: null,
      promise: null,
      codeOptions: {
        mode: "application/json",
        lineWrapping: true,
        lineNumbers: true,
        readOnly: true
      },
      deleteError: null
    };
  },

  created() {
    this.promise = this.load();
  },

  computed: {
    userPermissions() {
      return JSON.stringify(this.user.permissions, null, 2)
    },
    canEdit() {
      return this.user && this.$root.permissions.canPerform("update_user", {
        user: this.user.id
      });
    },
    canDelete() {
      return this.user && this.$root.permissions.canPerform("delete_user", {
        user: this.user.id
      });
    },
    IsForceChangePassword() {
      return this.user && this.user.status == "force_change_password" && this.$root.permissions.canPerform("create_user", {
        user: this.user.id
      });
    }
  },

  methods: {
    async load() {
      this.user = null;
      let response = await this.$api.get("/users/username:" + this.username);
      this.user = response.data;
    },
    async deleteUser() {
      try {
        if (await this.$refs.confirm.present({
          message: "Are you sure you want to delete this user?",
          confirm: "Delete",
          color: "error",
          callback: async () => {
            await this.$api.delete(`/users/${this.user.id}`);
          }
        })) {
          this.$router.push({name: "users", params: {statusAlert: "User deleted successfully"}});
        }
      } catch(err) {
        this.deleteError = err;
      }
    },
    async resendLoginLink() {
      this.user.resend_login_email = true;
      try {
        await this.$api.post("/users", this.user);
        this.$root.$emit("status-alert", {message: "Login link sent successfully", color: "success"});
      } catch(err) {
        this.$root.$emit("status-alert", {message: "Failed to send login link", color: "error"});
      }
    }
  }
};
</script>

<style scoped>
.editor >>> .CodeMirror {
  height: 400px;
}
</style>
