<template>
  <v-app>

    <div v-if="$root.isLoading" class="d-flex align-center justify-center fill-y">
      <v-progress-circular indeterminate size="50" width="3"></v-progress-circular>
    </div>

    <template v-else>
      <v-navigation-drawer v-if="!$root.isLogin" app dark :permanent="$vuetify.breakpoint.lgAndUp" v-model="drawer">
        <div class="text-h5 py-4 white--text d-flex align-center justify-center">
          <span class="bolt"></span>
          Airtime
        </div>
        <v-list nav>
          <template v-for="section, index in filteredMenu">
            <v-divider v-if="index > 0" :key="`divider-${index}`" :class="{'mb-5': !section.title}"></v-divider>
            <v-subheader v-if="section.title" :key="`header-${index}`">{{section.title}}</v-subheader>
            <template v-for="item in section.items">
              <tech-support-dialog v-if="item.isTechSupport" :key="item.title" />
              <v-list-item v-else :key="item.title" link :to="item.to" dense>
                <v-list-item-icon>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar v-if="!$root.isLogin" app :flat="$vuetify.breakpoint.lgAndUp"
        :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : null" :dark="!$vuetify.breakpoint.lgAndUp"
        :absolute="$vuetify.breakpoint.lgAndUp">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-lg-and-up"></v-app-bar-nav-icon>
        <v-toolbar-title class="hidden-lg-and-up">
          <div class="d-flex align-center">
            <span class="bolt"></span>
            Airtime
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item link v-on="on" v-bind="attrs">
                <user-avatar list :user="user" class="mr-0" />
                <v-list-item-content class="hidden-xs-only ml-3">
                  <v-list-item-title>{{ user.name || user.email }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="ml-1 hidden-xs-only">
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-card>
              <v-list dense>
                <v-list-item>
                  <user-avatar list :user="user" />
                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item :href="logoutURL">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Sign out</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import UserAvatar from "./components/UserAvatar"
import TechSupportDialog from "./components/TechSupportDialog.vue";

export default {
  name: 'App',
  components: { UserAvatar, TechSupportDialog },

  data: () => ({
    drawer: false,
  }),

  computed: {
    menu() {
      let p = this.$root.permissions;
      let canListProfiles = p.canPerformType("list_profiles", "profile");

      return [
        {
          'items': [
            {
              title: 'Dashboard',
              icon: 'mdi-monitor-dashboard',
              to: '/dashboard',
              condition: canListProfiles && p.canPerform("list_deliverables", "profile:*")
            },
            {
              title: 'Content',
              icon: 'mdi-image-multiple',
              to: '/content',
              condition: canListProfiles && p.canPerformType("list_deliverables", "profile")
            },
            {
              title: 'Stats',
              icon: 'mdi-chart-timeline-variant-shimmer',
              to:'/stats',
              condition: canListProfiles && p.canPerformType("get_stats_time_series", "profile")
            }
          ]
        },
        {
          "title": "Data",
          "items": [
            {
              title: 'Guest Data',
              icon: 'mdi-account-box-multiple',
              to: '/data',
              condition: canListProfiles && p.canPerformType("list_submissions", "profile")
            },
            {
              title: 'Deliveries', 
              icon: 'mdi-email-fast',
              to: '/deliveries',
              condition: canListProfiles && p.canPerformType("list_deliveries", "profile")
            },
            {
              title: 'Payments',
              icon: 'mdi-credit-card-multiple',
              to: '/payments',
              condition: canListProfiles && p.canPerformType("list_payments", "profile")
            }
          ]
        },
        {
          "title": "Design & Functionality",
          "items": [
            {
              title: 'Customisation',
              icon: 'mdi-pencil-ruler',
              to: '/profiles',
              condition: canListProfiles && p.canPerformType("view_profile_editor", "profile")
            },
            {
              title: "Delivery Templates",
              icon: "mdi-format-textbox",
              to: "/templates",
              condition: canListProfiles && p.canPerformType("list_delivery_templates", "profile")
            }
          ]
        },
        {
          "title": "Administration",
          "items": [
            {
              title: "Profiles",
              icon: "mdi-sitemap",
              to: "/profile-admin",
              condition: (
                canListProfiles && p.canPerformType("get_profile", "profile") &&
                (p.canPerformType("create_profile", "profile") || p.canPerformType("update_profile", "profile"))
              )
            },
            {
              title: "Users",
              icon: "mdi-account-multiple",
              to: "/users",
              condition: p.canPerformType("list_users", "user")
            }
          ]
        },
        {
          "items": [
            {
              title: "Monitoring",
              icon: "mdi-eye",
              to: "/monitoring",
              condition: canListProfiles && p.canPerformType("list_monitored_resources", "profile")
            },
            {
              title: "Tech Support",
              isTechSupport: true
            }
          ]
        }
      ];
    },

    filteredMenu() {
      return this.menu.map((item) => {
        let items = item.items.filter(subItem => subItem.condition === undefined || subItem.condition);
        if (!items.length) return null;
        return {...item, items};
      }).filter(item => item);
    },
      
    logoutURL() {
      let redirectURL = window.location.origin;
      return this.$apiBaseURL + '/session/logout?redirect_url=' + encodeURIComponent(redirectURL);
    },

    user() {
      return this.$root.user;
    }
  },

  watch: {
    "$root.isLoading"(newVal) {
      // quick hack to redirect to first menu item. Eventually we will probably have a dashboard page.
      if (!newVal && this.$route.path === "/") {
        this.$router.push(this.filteredMenu[0].items[0].to);
      }
    }
  }
};
</script>

<style>
.bolt {
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjFweCIgaGVpZ2h0PSIxMTNweCIgdmlld0JveD0iMCAwIDIxIDExMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkJvbHQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8cG9seWdvbiBpZD0icGF0aC0xIiBwb2ludHM9IjAgMCAyMSAwIDIxIDExMyAwIDExMyI+PC9wb2x5Z29uPgogICAgPC9kZWZzPgogICAgPGcgaWQ9IkZvb3RlciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk0LjAwMDAwMCwgLTExMC4wMDAwMDApIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NC4wMDAwMDAsIDEwOS4wMDAwMDApIiBpZD0iQm9sdCI+CiAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPgogICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICA8L21hc2s+CiAgICAgICAgICAgICAgICA8ZyBpZD0iQ2xpcC0yIj48L2c+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjAuMjczNTg4MiwwLjI1MTQwNTk4MSBMMC43NDkzMDE2NDUsNTguNTQ3NTgzNCBDMC41NDg3MzU5MTgsNTkuMTI1MDA1OCAwLjk3ODUxOTYxOCw1OS43Mjc1Mjc3IDEuNTkxMTEyNzIsNTkuNzI3NTI3NyBMOS4zMjE1Njk1OCw1OS43Mjc1Mjc3IEwwLjAwNjc2NDU0ODIsMTEyLjU2MjIxOCBDLTAuMDczMDA0MzgyNiwxMTMuMDEzNDcyIDAuNTc2MDQyOTg5LDExMy4xODE1MTcgMC43MjY0MzM2NTQsMTEyLjc0ODY1MiBMMjAuMjgyODY5OSw1NC4zOTYzNjk3IEMyMC40ODM0MzU2LDUzLjgxODk0NzMgMjAuMDUzNjUxOSw1My4yMTY0MjU0IDE5LjQ0MTA1ODgsNTMuMjE2NDI1NCBMMTEuNzEwNjAyLDUzLjIxNjQyNTQgTDIwLjk5MzI1NzMsMC40Mzc4NDAxNDQgQzIxLjA3Mjg5MTcsLTAuMDEzNDEzNzQ2NiAyMC40MjM4NDQ0LC0wLjE4MTU5MzczNiAyMC4yNzM1ODgyLDAuMjUxNDA1OTgxIiBpZD0iRmlsbC0xIiBmaWxsPSIjMDAwMDAwIiBtYXNrPSJ1cmwoI21hc2stMikiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: white;
  height: 2.5rem;
  width: 1.1rem;
  display: inline-block;
}

.v-app-bar .bolt {
  height: 1.9rem;
}

.fill-y {
  height: 100%;
}

body,
.v-application {
  background-color: #eee !important;
}

.v-application {
  padding-bottom: 2rem;
}

.v-app-bar {
  z-index: 6 !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.v-list-item:hover,
a.v-btn:hover {
  text-decoration: none;
}

.row-divided > *:not(:last-child) {
  border-right: solid thin;
  border-color: #e0e0e0;
  /* grey lighten-2 */
}
</style>
